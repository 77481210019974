export default [
  {
    component: 'CNavItem',
    name: 'داشبورد',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'کاربران',
    to: '/users',
    icon: 'cil-people',
  },
  {
    component: 'CNavItem',
    name: 'اپراتور‌ها',
    icon: 'cil-user',
    items:[
      {
        component: 'CNavItem',
        name:'تنظیم اپراتور',
        to: '/operators',
      },
      {
        component: 'CNavItem',
        name:'نوع و دسترسی',
        to: '/operators-access',
      }
  ]
  },
  {
    component: 'CNavItem',
    name: 'موجودی کیف پول‌ها',
    to: '/wallets-supply',
    icon: 'cil-wallet',
  },
  {
    component: 'CNavItem',
    name: 'احراز',
    to: '/',
    icon: 'cil-check',
    items: [
      {
        component: 'CNavItem',
        name: 'احراز هویت',
        to: '/kyc',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'احراز کارت',
        to: '/kyc-card',
        icon: '',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'آبان لینک',
    to: '/',
    icon: 'cil-check',
    items: [
      {
        component: 'CNavItem',
        name: 'احراز آبان لینک',
        to: '/kyc-aban-link',
        icon: '',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'وام',
    to: '/',
    icon: 'cil-money',
    items: [
      {
        component: 'CNavItem',
        name: 'تکمیل اطلاعات',
        to: '/loan/information',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'لیست وام‌ها',
        to: '/loan/loans',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'لیست اقساط',
        to: '/loan/installments',
        icon: '',
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'آبان کارت',
  //   to: '/',
  //   icon: 'cil-money',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'لیست کارت‌ها',
  //       to: '/aban-card/cards',
  //       icon: '',
  //     },
  //   ],
  // },
  {
    component: 'CNavItem',
    name: 'کیف پول‌ها',
    to: '/',
    icon: 'cil-wallet',
    items: [
      {
        component: 'CNavItem',
        name: 'انتقال بین کیف پول‌ها',
        to: '/wallets-transfer',
      },

      {
        component: 'CNavItem',
        name: 'اتومات انتقال',
        to: '/automatic-transfer',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'صرافی',
    to: '/',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'شبکه',
        to: '/networks',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'کوین',
        to: '/coins',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'اکانت',
        to: '/exchanges',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'ولت',
        to: '/wallets',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'تبدیل',
        to: '/manual-order',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'باکس',
        to: '/box',
        icon: '',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'پیام‌ها',
    to: '/',
    icon: 'cil-bell',
    items: [
      {
        component: 'CNavItem',
        name: 'پیام‌های خصوصی',
        to: '/private-messages',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'پیام‌های عمومی',
        to: '/public-messages',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'پیام‌های گروهی',
        to: '/bulk-messages',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'پاپ آپ',
        to: '/pop-ups',
        icon: '',
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'حسابداری اصلی',
  //   to: '/',
  //   icon: '',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'دابل چک',
  //   to: '/',
  //   icon: '',
  // },
  {
    component: 'CNavItem',
    name: 'حسابداری ارزی',
    to: '/',
    icon: 'cil-dollar',
    items: [
      {
        component: 'CNavItem',
        name: 'واریز',
        to: '/accounting/crypto/deposit',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'برداشت',
        to: '/accounting/crypto/withdrawal',
        icon: '',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'حسابداری ریالی',
    icon: 'cil-dollar',
    items: [
      {
        component: 'CNavItem',
        name: 'واریز',
        to: '/accounting/irt/deposit',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'برداشت',
        to: '/accounting/irt/withdrawal',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'قراردادهای واریز مستقیم',
        to: '/accounting/irt/direct-debit',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'تنظیمات واریز مستقیم',
        to: '/accounting/irt/direct-debit/banks',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'فیش های بانکی',
        to: '/accounting/irt/deposit-receipt',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'صورت حساب ها',
        to: '/accounting/irt/invoices',
        icon: '',
      }
    ],
  },

  {
    component: 'CNavItem',
    name: 'پرسنال ولت',
    icon: 'cil-dollar',
    items: [
      {
        component: 'CNavItem',
        name: 'آدرس‌های پرسنال ولت',
        to: '/personal-wallets/list',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'واریزها',
        to: '/personal-wallets/deposits',
        icon: '',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'گزارشات حسابداری',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'ثبت سند مالی',
        to: '/accounting-reports/financial-document',
        icon: '',
      },

      {
        component: 'CNavItem',
        name: 'گزارش دارایی قطعی',
        to: '/accounting-reports/definite-assets-report',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'معاملات تتر',
        to: '/accounting-reports/usdt-transactions',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'معاملات تتر (روزانه)',
        to: '/accounting-reports/usdt-daily-transactions',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش کارمزد ها',
        to: '/accounting-reports/fee-report',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش کارمزدها-روزانه',
        to: '/accounting-reports/fee-daily-report',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش تراز روزانه',
        to: '/accounting-reports/daily-balance-report',
        icon: '',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'گزارشات',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'کاربران',
        to: '/reports/users',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'ادمین ها',
        to: '/reports/admins',
      },
      {
        component: 'CNavItem',
        name: 'موجودی کیف پول‌ها',
        to: '/wallets-supply',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش تتری',
        to: '/reports/tether',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش فی',
        to: '/reports/fees',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش سود فی برداشت‌ ارزی',
        to: '/reports/crypto-withdrawal-fee-profit',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش روتین‌ بلاکرها',
        to: '/reports/blocker-routines',
        icon: '',
      },
      // {
      //   component: 'CNavItem',
      //   name: 'گزارش سوددهی',
      //   to: '/reports/profit',
      //   icon: '',
      // },

      {
        component: 'CNavItem',
        name: 'گزارش سوددهی',
        icon: '',
        items: [
          {
            component: 'CNavItem',
            name: 'OTC',
            to: '/OTC-profit',
            icon: '',
          },

          {
            component: 'CNavItem',
            name: 'P2P',
            to: '/P2P-profit',
            icon: '',
          },
        ],
      },

      {
        component: 'CNavItem',
        name: 'گزارش مالی',
        icon: '',
        items: [
          {
            component: 'CNavItem',
            name: 'گزارش تراکنش‌های تومانی',
            to: '/reports/transactions/fiat',
            icon: '',
          },

          {
            component: 'CNavItem',
            name: 'گزارش تراکنش‌های ارزی',
            to: '/reports/transactions/crypto',
            icon: '',
          },

          {
            component: 'CNavItem',
            name: 'تراکنش ها(خرید و فروش)',
            to: '/reports/transactions/trades',
            icon: '',
          },
        ],
      },

      {
        component: 'CNavItem',
        name: 'گزارش بازار',
        to: '/reports/market',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش طلب و بدهی',
        to: '/reports/extreme-users',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش معاملات دستی',
        to: '/reports/manualTransactions',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'حقوقی',
        icon: '',
        items: [
          {
            component: 'CNavItem',
            name: 'گزارش استعلام ولت ها',
            to: '/reports/wallet-transactions',
            icon: '',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'لیست گزارش‌ها',
        to: '/reports/list',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'هزینه‌ها',
        items: [
          {
            component: 'CNavItem',
            name: 'کمپین',
            to: '/reports/costs/campaign',
          },
          {
            component: 'CNavItem',
            name: 'رفرال',
            to: '/reports/costs/referral',
          },
          {
            component: 'CNavItem',
            name: 'فی صرافی',
            to: '/reports/costs/exchange-fee',
          },
        ],
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'بات',
    icon: 'cil-arrow-thick-bottom',
    items: [
      {
        component: 'CNavItem',
        name: 'گزارشات',
        to: '/bot-reports',
        icon: '',
      },

      {
        component: 'CNavItem',
        name: 'تنظیمات',
        to: '/bot-setting',
        icon: '',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'برداشت‌ها',
    icon: 'cil-arrow-thick-bottom',
    to: '/withdrawals',
  },
  {
    component: 'CNavItem',
    name: 'واریز‌ها',
    icon: 'cil-arrow-thick-top',
    to: '/deposits',
  },
  {
    component: 'CNavItem',
    name: 'خرید و فروش',
    icon: 'cib-deezer',
    items: [
      {
        component: 'OTC',
        name: 'OTC',
        icon: '',
        items: [
          {
            component: 'CNavItem',
            name: 'خرید و فروش',
            to: '/orders',
            icon: '',
          },
          {
            component: 'CNavItem',
            name: 'پیگیری تراکنش',
            to: '/order-tracking',
            icon: '',
          },
          {
            component: 'CNavItem',
            name: 'پیگیری تسویه',
            to: '/order-settlements',
            icon: '',
          },
        ],
      },

      {
        component: 'P2P',
        name: 'P2P',
        icon: '',
        items: [
          {
            component: 'CNavItem',
            name: 'سفارشات',
            to: '/p2p-order-tracking',
            icon: '',
          },
          {
            component: 'CNavItem',
            name: 'ترید ها',
            to: '/p2p-trading',
            icon: '',
          },
        ],
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'صندوق‌ها',
    icon: 'cil-dollar',
    items: [
      {
        component: 'CNavItem',
        name: 'صندوق‌ها',
        to: '/chest',
        icon: '',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'مارکتینگ',
    icon: 'cib-deezer',
    items: [
      {
        component: 'CNavItem',
        name: 'گزارش روزانه',
        to: '/marketing/dashboard',
        icon: '',
      },
      {
        component: 'CNavItem',
        name: 'گزارش سورس ها',
        to: '/marketing/sourceReport',
        icon: '',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'رفرال',
    to: '/referral/',
    icon: 'cil-warning',
  },

  {
    component: 'CNavItem',
    name: 'معاملات دستی',
    to: '/contracts/',
    icon: 'cil-warning',
  },
  {
    component: 'CNavItem',
    name: 'دابل چک',
    to: '/double-check',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'گزارش یوزر',
    icon: 'cil-notes',
    to: '/user-id-convert',
  },
  {
    component: 'CNavItem',
    name: 'تنظیمات',
    // to: '/settings',
    icon: 'cil-settings',
    items: [
      {
        component: 'CNavItem',
        name: 'تنظیمات',
        to: '/settings',
        // icon: 'cil-settings',
      },
      {
        component: 'CNavItem',
        name: 'تنظیمات‌ بازار',
        to: '/P2PSettings',
        // icon: 'cil-settings',
      },
      {
        component: 'CNavItem',
        name: 'تنظیمات‌ فی',
        to: '/FeeSettings',
        // icon: 'cil-settings',
      },
    ],
  },

  // {
  //   component: 'CNavTitle',
  //   name: 'Theme',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Colors',
  //   to: '/theme/colors',
  //   icon: '',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Typography',
  //   to: '/theme/typography',
  //   icon: 'cil-pencil',
  // },
  // {
  //   component: 'CNavTitle',
  //   name: 'Components',
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Base',
  //   to: '/base',
  //   icon: 'cil-puzzle',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Accordion',
  //       to: '/base/accordion',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Breadcrumbs',
  //       to: '/base/breadcrumbs',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Cards',
  //       to: '/base/cards',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Carousels',
  //       to: '/base/carousels',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Collapses',
  //       to: '/base/collapses',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'List Groups',
  //       to: '/base/list-groups',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Navs & Tabs',
  //       to: '/base/navs',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Paginations',
  //       to: '/base/paginations',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Placeholders',
  //       to: '/base/placeholders',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Popovers',
  //       to: '/base/popovers',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Progress',
  //       to: '/base/progress',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Spinners',
  //       to: '/base/spinners',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Tables',
  //       to: '/base/tables',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Tooltips',
  //       to: '/base/tooltips',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Buttons',
  //   to: '/buttons',
  //   icon: 'cil-cursor',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Buttons',
  //       to: '/buttons/standard-buttons',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Button Groups',
  //       to: '/buttons/button-groups',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Dropdowns',
  //       to: '/buttons/dropdowns',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Forms',
  //   to: '/forms',
  //   icon: 'cil-notes',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Form Control',
  //       to: '/forms/form-control',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Select',
  //       to: '/forms/select',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Checks & Radios',
  //       to: '/forms/checks-radios',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Range',
  //       to: '/forms/range',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Input Group',
  //       to: '/forms/input-group',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Floating Labels',
  //       to: '/forms/floating-labels',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Layout',
  //       to: '/forms/layout',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Validation',
  //       to: '/forms/validation',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Charts',
  //   to: '/charts',
  //   icon: 'cil-chart-pie',
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Icons',
  //   to: '/icons',
  //   icon: 'cil-star',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'CoreUI Icons',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'info',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Brands',
  //       to: '/icons/brands',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Flags',
  //       to: '/icons/flags',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Notifications',
  //   to: '/notifications',
  //   icon: 'cil-bell',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Modals',
  //       to: '/notifications/modals',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: 'cil-calculator',
  //   badge: {
  //     color: 'primary',
  //     text: 'NEW',
  //     shape: 'pill',
  //   },
  // },
  // {
  //   component: 'CNavTitle',
  //   name: 'Extras',
  // },
  // {
  //   component: 'CNavGroup',
  //   name: 'Pages',
  //   to: '/pages',
  //   icon: 'cil-star',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Login',
  //       to: '/pages/login',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Register',
  //       to: '/pages/register',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Error 404',
  //       to: '/pages/404',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Error 500',
  //       to: '/pages/500',
  //     },
  //   ],
  // },

  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
